import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import tw from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, ImageType, StyleType } from '../utils/prop-types'
import Image from './image'
import Text from './text'
import { ReactComponent as Close } from '../images/icon-close.svg'

const CookieConsent = ({ cookieName, icon, description, style }) => {
  const [cookies, setCookie] = useCookies()
  const [hidden, setHidden] = useState(false)
  const useSecure = (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) === 'production'

  const consentCookies = useCallback(() => {
    setCookie(cookieName, true, { maxAge: 2592000, path: '/', secure: useSecure, sameSite: 'lax' })
  }, [cookieName, setCookie, useSecure])

  useEffect(() => {
    setHidden(cookies[cookieName])
  }, [cookies, cookieName])

  return (
    <>
      <aside
        css={[
          hidden && tw`hidden`,
          tw`bg-white border-2 border-primary-500 w-full fixed bottom-0 pt-10 pb-2 z-20
          lg:(w-7/10 top-36 h-auto bottom-auto self-center py-2 pl-2 pr-1.5 xxl:w-2/4)`,
          style,
        ]}
      >
        <div
          css={[
            global`layout.container`,
            tw`flex flex-col items-center space-y-8
            lg:(left-1/2 self-center flex-row space-y-0 space-x-16)`,
          ]}
        >
          <div css={tw`flex flex-row items-center space-x-6`}>
            <div css={tw`flex-none w-20 h-20 lg:(w-16 h-16)`}>
              <Image image={icon} style={tw`bg-blue-dark `} />
            </div>
            <Text content={description} textType="body-md" />
          </div>
          <div css={tw`flex flex-row flex-none space-x-4`}>
            <button
              type="button"
              css={tw`absolute top-0 right-0 w-12 h-12 cursor-pointer bg-white`}
              onClick={consentCookies}
            >
              <div
                css={[
                  tw`flex flex-wrap content-center w-full h-full`,
                  tw`lg:(transition-transform duration-300 ease-in-out) lg:hover:(transform rotate-90)`,
                ]}
              >
                <Close css={tw`m-auto`} />
              </div>
            </button>
          </div>
        </div>
      </aside>
    </>
  )
}

CookieConsent.defaultProps = {
  cookieName: 'banner-announcement',
}

CookieConsent.propTypes = {
  cookieName: PropTypes.string,
  icon: ImageType,
  description: HTMLType,
  style: StyleType,
}

export default CookieConsent
