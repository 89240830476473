import { HelmetDatoCms } from 'gatsby-source-datocms'
import PropTypes from 'prop-types'
import React from 'react'
import { usePageContext } from '../context/page-context'
import { useLocation } from '@reach/router';

function SEO({ pageTags, faviconTags, siteUrl, noIndex }) {
  const { originalPath } = usePageContext()
  const location = useLocation();
  const lang = location.pathname.includes('poland') ? 'pl' : 'en';

  const favicon = {
    tags: [
      ...faviconTags.filter((t) => t.attributes.name !== 'application-name'),
      { attributes: { name: 'application-name', content: 'bolttech.io' }, tagName: 'meta' },
    ],
  }

  return (
    <HelmetDatoCms seo={pageTags} favicon={favicon}>
      <html lang={lang} />
      {originalPath === '/' ? (
        <link rel="canonical" href={`${siteUrl}${originalPath}`} />
      ) : (
        <link rel="canonical" href={`${siteUrl}${originalPath}/`} />
      )}
      {noIndex && <meta name="robots" content="noindex" />}
      <script>
        {`!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
        posthog.init('phc_kW2HN9OwKrZXtAJZBvFyTY33QFEOJQAzIYCdzFV7icB',{api_host:'https://eu.posthog.com'})`}
      </script>
      <script type="text/javascript">
        {`function getCookie(name) {
          var value = "; " + document.cookie;
          var parts = value.split("; " + name + "=");
          if (parts.length == 2) {
              return true;
          }
        }
        function reloadOTBanner() {
            var otConsentSdk = document.getElementById("onetrust-consent-sdk");
            if (otConsentSdk) {
                otConsentSdk.remove();
            }
            if (window.OneTrust != null) {
                OneTrust.Init();
                setTimeout(function() {
                    OneTrust.LoadBanner();
                    var toggleDisplay = document.getElementsByClassName(
                        "ot-sdk-show-settings"
                    );
                    for (var i = 0; i < toggleDisplay.length; i++) {
                        toggleDisplay[i].onclick = function(event) {
                            event.stopImmediatePropagation();
                            window.OneTrust.ToggleInfoDisplay();
                        };
                    }
                }, 1000);
            }
        }`}
      </script>
      <script type="text/plain" className="optanon-category-C0002">
        {`window._vwo_code = window._vwo_code || (function(){
        var account_id=575797,
        settings_tolerance=2000,
        library_tolerance=2500,
        use_existing_jquery=false,
        is_spa=1,
        hide_element='body',

        /* DO NOT EDIT BELOW THIS LINE */
        f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
        window.settings_timer=setTimeout(function () {_vwo_code.finish() },settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());`}
      </script>
    </HelmetDatoCms>
  )
}

SEO.propTypes = {
  pageTags: PropTypes.object,
  faviconTags: PropTypes.arrayOf(PropTypes.object),
  siteUrl: PropTypes.string,
  noIndex: PropTypes.bool,
}

export default SEO
