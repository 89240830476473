import React, { useState } from 'react'
import tw, { css } from 'twin.macro'
import { Modal } from 'react-responsive-modal'
import { global } from '../styles/global'
import { LinkType, StyleType } from '../utils/prop-types'
import Image from './image'
import Link from './link'
import Text from './text'
import Button from './button'
import 'react-responsive-modal/styles.css'

const MarketSelector = ({ link, style }) => {
  const [open, setOpen] = useState(false)

  const onOpenModal = () => {
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
  }

  const otherMarkets = link.navigation[0].subLinks

  return (
    <div
      css={[
        css`
          ${tw`relative h-10`}
        `,
        style,
      ]}
    >
      <button
        onClick={onOpenModal}
        type="button"
        css={[tw`flex items-center h-10 bg-white w-46 lg:(hidden)`, global`base.hover-underline`()]}
      >
        <Text content="Languages / Markets" textType="subtitle" style={tw`font-medium ml-3.5`} />
        <Image image={link.navigation[0].image} style={tw`ml-2 py-3 w-10`} />
      </button>
      <Button
        theme="white-blue"
        imageIcon={link.navigation[0].image}
        label={link.label}
        size="xs"
        onClick={onOpenModal}
        hideCaret
        buttonType="button"
        style={tw`hidden lg:flex`}
      />
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{ smallwidth: 'sm:w-full', mxwidth: 'max-w-full' }}
      >
        <div css={tw`w-full lg:(px-4)`}>
          <div
            css={[
              css`
                ${tw`relative h-10 h-full`}
              `,
            ]}
          >
            {otherMarkets && otherMarkets.length ? (
              <div css={tw`px-4 py-6 bg-white lg:(w-108 top-20)`}>
                <div css={tw`flex flex-col`}>
                  <Text
                    textType="h6"
                    content="Other languages / markets"
                    style={tw`font-medium mb-4`}
                  />
                  <div
                    css={tw`cursor-pointer flex flex-col grid grid-cols-2 gap-x-2 lg:(grid grid-cols-3 gap-x-3 mt-0)`}
                  >
                    {otherMarkets.map((subLink) => (
                      <div key={subLink.label} css={tw`flex flex-col items-start mt-2`}>
                        <Link
                          state={{ noScroll: true }}
                          to={subLink.link}
                          style={tw`border border-primary-300 w-full py-2`}
                          onClick={() => reloadOTBanner()}
                        >
                          <Text
                            content={subLink.label}
                            textType="subtitle"
                            style={tw`font-bold px-4`}
                          />
                          <Text
                            content={subLink.description}
                            textType="body-xs"
                            style={tw`font-light px-4`}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : undefined}
          </div>
        </div>
      </Modal>
    </div>
  )
}

MarketSelector.propTypes = {
  link: LinkType,
  style: StyleType,
}

export default MarketSelector
