import { format as dateFormat, parseISO, isValid as isValidDate } from 'date-fns'

export const format = (date = new Date(), formatString = 'dd MMMM yyyy') => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }

  if (!isValidDate(date)) {
    return 'Invalid date'
  }

  return dateFormat(date, formatString)
}
